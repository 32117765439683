import React from 'react';
import { renderWhatWeOffer } from 'route/Landing/sections/04_WeOffer'
import Layout from "../components/Layout"
import 'styles/pages/_WeOffer.scss';

export default function HowToUseIt({location}){
  return (
    <Layout location={location} t="el">
      <div className="page-we-offer fade-in">
        {renderWhatWeOffer('el')}
      </div>
    </Layout>
  )
}